#nprogress .bar {
  background: linear-gradient(90deg, $color-tertiary, $color-primary);
  height: 5px;
}

#nprogress .peg {
  box-shadow: 0 0 10px $color-primary, 0 0 5px $color-primary, 0 0 15px white;
}

#nprogress .spinner-icon {
  border-top-color: $color-primary;
  border-left-color: $color-primary;
  border-width: 3px;
  width: 24px;
  height: 24px;
}