.pz-posts {
  &__content {
    display: grid;
    grid-gap: $gutter-20;
    padding: $gutter-20 $gutter-10 $gutter-80;
    grid-template-areas: "video" "description" "content";
    grid-template-columns: 1fr;
    align-items: start;

    @include from(tablet) {
      padding: $gutter-90 $gutter-80;
      grid-template-areas: "video description" "content description";
      grid-template-columns: 2fr 1fr;
    }
  }

  &__head {
    display: grid;
    grid-gap: $gutter-20;
    padding: $gutter-20 0;
    border-bottom: 1px solid $color-default;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    background: white;
    position: sticky;
    z-index: map_get($z-index, "navbar-menu")-1;
    top: 3.5rem;
    &--item {
      font-style: bold;
    }
    &--item:hover {
      cursor: pointer;
    }
    &--active {
      color: $color-primary;
    }
  }

  &__data {
    grid-area: video;
    display: grid;
    grid-gap: $gutter-20;
    &--linepad {
      padding-top: $gutter-40;
    }
    &--info {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      max-height: 10rem;
      overflow: auto;
      @include from(tablet) {
        height: 10rem;
      }
    }
    &--modalinfo {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      max-height: 10rem;
      overflow: auto;
    }
  }

  &__description {
    grid-area: description;
    display: grid;
    grid-gap: $gutter-20;
  }

  &__file {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: $gutter-20;
  }

  &__load-button {
    padding-bottom: 1rem;
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  &__card {
    &-item {
      border: 1px solid $color-default;
      border-radius: $border-radius;
      display: grid;
      grid-template-rows: auto 1fr auto auto;
      cursor: pointer;

      &--private {
        color: $color-alert;
      }

      &--type {
        background: $color-common;
        color: $color-common-contrast;
        padding: $gutter-10;
        border-radius: $border-radius $border-radius 0 0;
        display: grid;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        grid-gap: $margin;
        justify-content: space-between;
      }

      &--content {
        padding: $gutter-10;
        display: grid;
        align-content: start;
        overflow: hidden;
      }

      &--description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &--reactions {
        display: grid;
        grid-gap: $gutter-10;
        grid-auto-flow: column;
        padding: $gutter-10 $gutter-10 0;
        justify-content: start;
      }

      &--reaction {
        display: grid;
        grid-auto-flow: column;
        grid-gap: $gutter-5;
        align-items: center;
      }

      &--user {
        display: grid;
        grid-gap: $gutter-5;
        align-items: center;
        justify-content: start;
        grid-auto-flow: column;
        margin-top: $gutter-10;
        background: $color-gray-6;
        padding: $gutter-5 $gutter-10;
        font-size: $h5-font-size;

        img {
          @include size(1.5em);
          object-fit: cover;
          border-radius: 50%;
          align-self: start;
        }
      }
    }
  }

  &__modal {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gutter-40;
    width: 100%;

    @include from(tablet) {
      grid-template-columns: 2fr 1fr;
      width: 90vw;
    }
    &--panel {
      @include from(tablet) {
      }
    }
  }

  &__badge {
    display: grid;
    grid-gap: $gutter-16;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
    padding: $gutter-10;
    background: $color-gray-6;
    box-shadow: inset 3px 0 0 $color-primary;

    img {
      @include size(2em);
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.better-video-detail-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease;
}

.better-video-detail-modal {
  background-color: #fff;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  animation: slideIn 0.3s ease;
}

.video-wrapper {
  margin-bottom: 20px;
}

.video-info {
  margin-bottom: 20px;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.caption {
  margin-bottom: 10px;
}

.engagement {
  display: flex;
  align-items: center;
}

.like-btn,
.comment-btn,
.share-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
