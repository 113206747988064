.pz-course-user-badge {
  display: grid;
  grid-gap: $gutter-16;
  align-items: start;
  justify-content: start;
  grid-auto-flow: column;
  padding: $gutter-16;
  background: $color-gray-6;
  box-shadow: inset 3px 0 0 $color-primary;

  img {
    @include size(4em);
    object-fit: cover;
    border-radius: 50%;
  }
}