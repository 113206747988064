.pz-news {
  position: sticky;
  z-index: map_get($z-index, "navbar-menu") -5;
  display: grid;
  grid-auto-flow: column;
  background: $color-secondary;
  color: $color-primary-contrast;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  @include from(tablet) {
    padding: 1rem 2rem;
  }

  &__item {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
    @include from(tablet) {
      grid-auto-flow: column;
    }

    &--description {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 1rem;
      justify-content: center;
      align-items: center;
      @include from(tablet) {
        grid-auto-flow: column;
      }
    }
  }
  &__actions {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;
    align-items: center;
  }
  &__action:hover {
    cursor: pointer;
  }
  &__button {
    background: $color-primary-contrast;
    color: $color-secondary;
    max-width: fit-content;
    justify-self: center;
  }

  &__close-button {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 15px !important;
    height: 15px !important;
    cursor: pointer;
  }

  &__card {
    &-item {
      border: 1px solid $color-default;
      border-radius: $border-radius;
      display: grid;
      grid-template-rows: auto 1fr;

      &--private {
        color: $color-alert;
      }

      &--type {
        background: $color-common;
        color: $color-common-contrast;
        padding: $gutter-10;
        border-radius: $border-radius $border-radius 0 0;
        display: grid;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        grid-gap: $margin;
        justify-content: space-between;
      }

      &--content {
        padding: $gutter-10;
        display: grid;
        align-content: start;
        overflow: hidden;
      }

      &--description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
