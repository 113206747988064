.pz-auth-form {
  overflow: auto;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &__form-section {
    display: grid;
    align-content: center;
    padding: $gutter-40 $gutter-40 $gutter-80;

    @include from(mobile-l) {
      justify-content: center;
    }

    @include from(tablet) {
      padding: $gutter-80 * 2 $gutter-80;
    }
  }

  &__title {
    margin-bottom: $gutter-40;
  }

  &__message {
    font-size: $small-font-size;
    margin: $gutter-40 0;
    width: 100%;

    @include from(mobile-l) {
      max-width: 350px;
    }
  }

  &__form {
    display: grid;
    grid-gap: $gutter-20;
  }

  &__content {
    background: white;
    padding: $gutter-40;
    border-radius: $border-radius;
    border: 1px solid $color-gray-5;
    box-shadow: 0 35px 15px -20px rgba(black, 0.5);
    position: relative;

    @include from(mobile-l) {
      min-width: 350px;
    }

    @include from(tablet) {
      &::before, &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
      }

      &::before {
        top: 70%;
        right: 100%;
        width: 100px;
        height: 100px;
        background: $color-secondary;
        transform: translate(25%, -50%);
        box-shadow: 20px 0 15px -20px rgba(black, 0.5);
      }

      &::after {
        bottom: 100%;
        left: 100%;
        width: 150px;
        height: 150px;
        background: $color-primary;
        transform: translate(-50%, 50%);
        box-shadow: -20px 20px 15px -20px rgba(black, 0.5);
      }
    }

  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-auto-columns: 1fr;
    align-items: center;
    grid-gap: $gutter-10;
    margin-top: $gutter-40;
  }
}