.pz-profile {
  &__actions {
    display: grid;
    grid-gap: $gutter-40;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
  }

  &__tasks {
    display: grid;
    grid-gap: $gutter-20;
    grid-auto-flow: row;
    justify-self: center;
    text-align: center;
    max-width: fit-content;
    padding: $gutter-20 $gutter-40;
    background: $color-gray-6;
    border-radius: $border-radius;

    &--content {
      display: grid;
      grid-gap: $gutter-20;
      grid-auto-flow: row;
      justify-self: center;
      text-align: center;
      max-height: 7rem;
      overflow: auto;
      padding: 0.5rem;
    }
    &--content::-webkit-scrollbar {
      width: 0.5rem;
    }

    &--content::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px $color-gray-4;
      border-radius: 10px;
    }

    &--content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $color-secondary;
    }

    &--button {
      background-color: $color-primary-contrast;
    }
  }

  &__trivia {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    grid-gap: $gutter-20;

    &--options {
      padding: $gutter-20;
      display: grid;
      grid-gap: $gutter-10;
      grid-auto-flow: row;
    }
    &--img {
      height: 10rem;
    }
    &--imgresults {
      @include from(tablet) {
        @include size(20rem);
      }
      @include size(10rem);
      object-fit: fill;
    }
    &--actions {
      display: grid;
      grid-gap: $gutter-20;
      grid-auto-flow: column;
      justify-items: center;
    }
  }

  &__content {
    display: grid;
    grid-gap: $gutter-40;
    padding: $gutter-40 $gutter-40 $gutter-80;

    @include from(tablet) {
      padding: $gutter-80 0;
    }
  }

  &__employees {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__courses {
    display: grid;
    grid-gap: $gutter-10;

    &--title {
      border-bottom: 1px solid $color-gray-4;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      justify-content: space-between;
    }

    &--list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      grid-gap: $gutter-20;
      padding: $gutter-10 0;

      &.empty {
        justify-content: center;
        align-items: center;
        justify-items: center;
        grid-template-columns: 1fr;
        height: $gutter-80;
        text-align: center;
      }

      &.recommend {
        justify-content: center;
        align-items: center;
        justify-items: center;
        grid-template-columns: 1fr;
        text-align: center;
      }
    }
  }

  &__tabcontainer {
    display: grid;
    padding-top: 2rem;
  }

  &__tabs {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    padding-bottom: 2rem;
  }
  &__tab {
    margin-right: 20px;
    font-family: $font-family-bold;
  }
  &__tab:hover {
    cursor: pointer;
  }

  &__active-tab {
    font-family: $font-family-bold;
    margin-right: 20px;
    border-bottom: 2px solid $color-primary;
  }

  &__404 {
    display: grid;
    grid-gap: $gutter-40;
    align-content: center;
    min-height: 100vh;
    justify-items: center;

    img {
      width: 35rem;
    }
  }
}
