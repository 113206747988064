*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 14px;

  @include from(tablet) {
    font-size: 16px;
  }
}

body {
  color: $color-common;
  font-family: $font-family-regular;
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6,
small {
  font-size: $h6-font-size;
}

p,
label {
  font-size: $p-font-size;
  line-height: $line-height;
}

span,
a {
  font-size: 1em;
  line-height: $line-height;
}

p {
  padding: $padding / 2 0;
}

a {
  text-decoration: none;
  color: inherit;
}

blockquote {
  font-size: $small-font-size;
  background: rgba(white, 0.15);
  padding: $gutter-16;
  margin: $gutter-10 0;
}

button {
  background: $color-default;
  color: $color-default-contrast;
  border: 1px solid;
  cursor: pointer;
  font-family: $font-family-bold;
  font-size: $button-font-size;
  transition: all 0.3s ease;
  outline: 0;

  &:active:hover {
    transform: scale(0.96);
  }
}

img {
  max-width: 100%;
  display: inline-block;
  object-fit: cover;
}

audio,
video {
  outline: 0;
}

s {
  color: $color-alert;
}
