
.pz-form-modal {
  display: grid;
  grid-template-areas: 'content image';
  grid-template-columns: repeat(2, 28.125rem);
  grid-gap: $gutter-80;
  align-items: center;

  &.invert {
    grid-template-areas: 'image content';
  }

  &__image {
    align-self: center;
    justify-self: center;
    grid-area: image;
    width: 25rem;
  }

  &__content {
    grid-area: content;
    display: grid;
    grid-template-areas: 'title' 'children' 'actions';
    grid-gap: $gutter-20;
  }

  &__title {
    grid-area: title;
  }

  &__children {
    grid-area: children;
    display: grid;
    grid-gap: $gutter-20;
  }

  &__actions {
    grid-area: actions;
    justify-self: right;
  }
}