.pz-modal {
  @at-root &__overlay {
    background: rgba($color-gray-2, 0.5);
    position: fixed;
    z-index: map_get($z-index, modal);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.transparent {
      background: transparent;
    }
  }

  @at-root &__content {
    position: fixed;
    z-index: map_get($z-index, modal) + 1;
    top: 0;
    left: 0;
    background: white;
    overflow: auto;
    border-radius: 0;
    @include size(100%, 100vh);

    @include from(tablet) {
      top: 50%;
      left: 50%;
      &.bigger-modal {
        min-width: 800px;
      }

      @include size(initial);
      max-height: 90vh;
      transform: translate(-50%, -50%);
      border-radius: $border-radius;
    }

    &.transparent {
      background: transparent;
    }
  }

  @at-root &__children {
    padding: $gutter-80 $gutter-10;

    @include from(tablet) {
      padding: $gutter-40 $gutter-20;
    }
  }

  @at-root &__close-button {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 18px !important;
    height: 18px !important;
    cursor: pointer;
  }

  @at-root &__page {
    position: absolute;
    top: 18px;
    left: 18px;
    width: 18px !important;
    height: 18px !important;
    cursor: pointer;
  }
}
