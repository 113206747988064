.pz-course-modules {
  display: grid;
  grid-gap: 1px;
  overflow: hidden;
  background: $color-gray-6;
  box-shadow: inset 3px 0 0 $color-primary;

  &__module {
    padding: $gutter-20;
    border-bottom: 1px solid;

    &:last-child {
      border-bottom: 0;
    }

    &--header {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &--body {
    }

    &--title {
    }

    &--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &--info {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
    }

    &--arrows {
      display: grid;
      grid-gap: 1rem;
    }

    &--lesson {
      font-size: $p-font-size;
      display: grid;
      grid-template-columns: 1fr auto;
      transition: all 0.1s ease;

      &--actions {
        display: grid;
        grid-auto-flow: column;
        align-self: center;
        grid-gap: $gutter-20;
        padding-right: $gutter-40;

        &--edit {
          align-self: center;
          cursor: pointer;
        }
      }

      &--content {
        display: grid;
        grid-template-areas: "view2  title " "icon description";
        grid-template-columns: auto 1fr;
        align-items: start;
        grid-gap: $gutter-5 $gutter-16;
        padding: $gutter-10;
        cursor: pointer;
        @include from(tablet) {
          display: grid;
          grid-template-areas: "view2 icon title " ".  . description";
          grid-template-columns: auto auto 1fr;
          align-items: start;
          grid-gap: $gutter-5 $gutter-16;
          padding: $gutter-10;
        }
      }

      &:hover,
      &.selected {
        background: $color-gray-5;
      }

      &:first-child {
        margin-top: $gutter-20;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--title {
        grid-area: title;
      }

      &--icon {
        grid-area: icon;
        align-self: center;
      }

      &--view {
        grid-area: view2;
        align-self: center;
        color: $color-gray-4;
        &--active {
          grid-area: view2;
          align-self: center;
          color: $color-active;
        }
      }

      &--description {
        grid-area: description;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  &__quiz {
    display: grid;
    grid-gap: 1px;
    overflow: hidden;
    background: $color-gray-6;
    box-shadow: inset 3px 0 0 $color-secondary;
    padding: $gutter-20;
    padding: $gutter-20;
    border-top: 1px solid;

    &--header {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
    }

    &--body {
      cursor: pointer;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      align-items: center;
    }

    &--title {
    }

    &--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &--active {
      color: $color-active;
    }
    &--secondary {
      color: $color-secondary;
    }
  }

  &__forum {
    display: grid;
    grid-gap: 1px;
    overflow: hidden;
    background: $color-gray-6;
    box-shadow: inset 3px 0 0 $color-common;
    padding: $gutter-20;
    padding: $gutter-20;
    border-bottom: 1px solid;

    &--header {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
    }

    &--body {
      cursor: pointer;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      align-items: center;
    }

    &--title {
    }

    &--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &--active {
      color: $color-active;
    }
    &--secondary {
      color: $color-common;
    }
  }

  &__forum2 {
    display: grid;
    grid-gap: 1px;
    overflow: hidden;
    background: $color-gray-6;
    box-shadow: inset 3px 0 0 $color-common;
    padding: $gutter-20;
    padding: $gutter-20;
    border-top: 1px solid;

    &--header {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
    }

    &--body {
      cursor: pointer;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      align-items: center;
    }

    &--title {
    }

    &--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &--active {
      color: $color-active;
    }
    &--secondary {
      color: $color-common;
    }
  }

  &__module2 {
    padding: $gutter-20;
    border-bottom: 1px solid;
    @include from(tablet) {
      min-width: 800px;
    }
  }
}
