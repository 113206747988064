$balls: 4;
$ball_size: 3vh;
$time: 5s;
$circle_size: 25vh; // Puedes ajustar este valor según tus necesidades
$circle_max_size: 40vh;


.pz-loading-modal__overlay {
  z-index: map_get($z-index, modal) + 50;
}

.pz-loading-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: map_get($z-index, modal) + 51;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; 
  overflow: hidden; 
  width: min($circle_size, $circle_max_size);
  height: min($circle_size, $circle_max_size);
}

.pz-loading-modal__loader {
  height: $ball_size * 5.5;
  width: $ball_size * 5.5; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: flex-end;
 

  span {
    width: $ball_size;
    height: $ball_size;
    border-radius: 50%;
    background: rgba($blue-light, 0.0);
    display: block;
    margin: 0 2px;
    animation: bounce $time/$balls ease infinite, color $time ease infinite;
    position: relative;

    @for $i from 1 through $balls {
      &:nth-child(#{$i}) {
        animation-delay: 0.15s * $i;
      }
    }
  }
}











