@font-face {
  font-family: Volte-Rounded-Regular;
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/VolteRounded/VolteRounded-Regular.otf');
}

@font-face {
  font-family: Poppins-ExtraLight;
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/Poppins/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: Poppins-Light;
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

$line-height: 1.5em;

$font-family-title: Volte-Rounded-Regular, Poppins-SemiBold, sans-serif;
$font-family-light: Poppins-ExtraLight, sans-serif;
$font-family-regular: Poppins-Light, sans-serif;
$font-family-bold: Poppins-SemiBold, sans-serif;

$h1-font-size: 2.5rem;
$h2-font-size: 2.0rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.0rem;
$h5-font-size: 0.75rem;
$h6-font-size: 0.5625rem;

$p-font-size: 0.875rem;
$table-font-size: 0.75rem;
$button-font-size: 0.75rem;

$small-font-size: 0.75rem;
