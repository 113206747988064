.pz-courses {
  display: grid;
  grid-gap: $gutter-10;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;

  &__container {
    display: grid;
    grid-gap: $gutter-20;
    width: 100%;
    align-content: start;
    padding: $gutter-40;

    @include from(tablet) {
      padding: 0;
      padding: $gutter-40 0;
    }
  }

  &__filter {
    display: grid;
    grid-gap: $gutter-20;
    grid-template-columns: 1fr;
    align-items: end;
    justify-content: start;
    margin-bottom: 4rem;

    @include from(tablet) {
      grid-gap: $gutter-40;
      grid-template-columns: 15rem 10rem;
    }
  }

  &__courses {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: $gutter-20;
    padding: $gutter-10 0;

    &__tabcontainer {
      margin: 2rem 0 4rem 0;
      display: grid;
      justify-content: center;
    }

    &__tabs {
      @include from(tablet) {
        display: flex;
        flex-direction: row;
        padding: 0 2rem;
        justify-content: start;
      }
    }
    &__tab {
      margin-right: 20px;
    }
    &__tab:hover {
      cursor: pointer;
    }

    &__active-tab {
      margin-right: 20px;
      border-bottom: 2px solid $color-primary;
    }

    &__carousel {
      display: grid;
      justify-content: center;
      @include from(mobile-s) {
        grid-template-columns: repeat(auto-fill, minmax(15rem, 17.9rem));
      }
      @include from(tablet) {
        grid-template-columns: repeat(auto-fill, minmax(15rem, 46.6rem));
      }

      @include from(laptop) {
        grid-template-columns: repeat(auto-fill, minmax(15rem, 78.2rem));
      }
    }

    &__carousel-item {
      padding: 10px;
      padding-bottom: 30px;
      padding-top: 10px;
    }

    &__all {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      justify-items: center;
    }

    &.empty {
      justify-content: center;
      align-items: center;
      justify-items: center;
      grid-template-columns: 1fr;
      height: $gutter-80;
      text-align: center;
    }
  }
  &__load__button {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}

.pz-course {
  &__content {
    display: grid;
    grid-gap: $gutter-40;
    padding: $gutter-40 $gutter-40 $gutter-80;
    grid-template-areas: "header" "actions" "modules" "extra";
    grid-template-columns: 1fr;
    align-items: start;

    @include from(tablet) {
      padding: $gutter-40 0 $gutter-80;
      grid-gap: $gutter-20 $gutter-40;
      grid-template-areas: "header header" "actions actions" "modules extra";
      grid-template-columns: 2fr 1fr;
    }
  }

  &__badge {
    background: $color-secondary;
    color: $color-secondary-contrast;
    padding: 0 $gutter-10;
  }

  &__level {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gutter-10;
    align-items: start;
    justify-content: start;

    &--active {
      background-color: $color-secondary;
    }
    &--inactive {
      background-color: $color-gray-5;
    }
    &--icon {
      display: flex;
      align-items: end;
      &--bar-1 {
        width: 4px;
        margin-right: 2px;
        border-radius: 2px;
        height: 8px;
      }

      &--bar-2 {
        width: 4px;
        margin-right: 2px;
        border-radius: 2px;
        height: 12px;
      }

      &--bar-3 {
        width: 4px;
        margin-right: 2px;
        border-radius: 2px;
        height: 16px;
      }
    }
  }

  &__badge--private {
    background: $color-alert;
    color: $color-alert-contrast;
    padding: 0 $gutter-10;
  }

  &__header {
    grid-area: header;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "image" "badges" "content";
    grid-gap: $gutter-10;

    @include from(tablet) {
      grid-gap: $gutter-10 0;
      grid-template-columns: 3fr 2fr;
      grid-template-rows: auto minmax(15rem, 1fr) 1rem;
      grid-template-areas: "badges image" "content image" ". image";
    }

    &--content {
      grid-area: content;
      background: $color-common;
      color: $color-common-contrast;
      display: grid;
      align-content: center;
      padding: $gutter-40 $gutter-40 $gutter-20;
    }

    &--actions {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 1em;
    }

    &--tags {
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-10;
      justify-content: start;
      align-items: center;
      margin-top: 1em;
      &--tag {
        padding: $gutter-5 $gutter-10;
        background-color: $color-secondary;
        border-radius: $gutter-10;
      }
    }

    &--icon:hover {
      cursor: pointer;
    }

    &--info {
      margin-top: 5rem;
      display: grid;
      grid-auto-flow: row;
      @include from(tablet) {
        margin-top: 5rem;
        display: grid;
        grid-auto-flow: column;
      }
    }

    &--badges {
      grid-area: badges;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-10;
      justify-content: start;
    }
  }

  &__thumbnail {
    @include size(10rem);
    display: block;
    object-fit: cover;
    grid-area: image;
    border-radius: 50%;
    justify-self: center;
    border: 3px solid white;
    margin: $gutter-20;
    box-shadow: 0 0 0 $gutter-20 $color-primary;

    @include from(tablet) {
      @include size(100%);
      border-radius: 0;
      box-shadow: 0 0 0;
      border: 0;
      margin: 0;
    }
  }

  &__actions {
    grid-area: actions;
    &--pc {
      @include from(tablet) {
        justify-content: start;
        display: grid;
        grid-auto-flow: column;
        grid-gap: $gutter-5;
      }
      display: none;
    }
    &--mobile {
      @include from(tablet) {
        display: none;
      }
      display: grid;
      justify-content: start;
      padding-left: $gutter-20;
    }
  }

  &__extra {
    grid-area: extra;
    display: grid;
    grid-gap: $gutter-20;
    align-content: start;
    &--linepad {
      padding-top: $gutter-40;
    }
  }

  &__reactions {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gutter-10;
    justify-content: start;
  }

  &__reaction {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gutter-5;
  }
}

.custom-dot-list-style2 button {
  border: none;
  background: $color-gray-5;
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: $color-primary !important;
}
.react-multiple-carousel__arrow {
  background: rgba(246, 113, 119, 0.5);
  z-index: map_get($z-index, "navbar-menu")-1;
}
.react-multiple-carousel__arrow:hover {
  background: rgba(246, 113, 119, 1);
}
