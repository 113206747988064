.pz-button {
  border-radius: $border-radius;
  border: 0;
  padding: 0 2ex;
  box-shadow: 0 12px 5px -8px rgba(black, 0.45);
  height: 3em;
  transition: all 0.3s ease;

  &.icon {
    min-width: unset;
    padding: 0.5em;
    @include size(2.125rem);
  }

  &.thin {
    height: 2.5em;

    &.icon {
      @include size(1.6875rem);
    }
  }

  &:disabled {
    background: $color-disabled;
    color: $color-disabled-contrast;
    cursor: not-allowed;
  }

  &.ghost {
    background: transparent;
    border: 1px solid;

    @each $key, $value in $colors {
      &.#{$key} {
        color: map_get($value, color);
      }
    }

    &:disabled {
      color: $color-disabled;
    }
  }

  &.whiteghost {
    background: white;
    border: 1px solid;

    @each $key, $value in $colors {
      &.#{$key} {
        color: map_get($value, color);
      }
    }

    &:disabled {
      color: $color-disabled;
    }
  }

  &.share {
    background: white;
    border: 1px solid;
  }

  &__facebook {
    background-color: #3b5998; /* Facebook Blue */
    color: #fff; /* White text */
    cursor: pointer; /* Cursor style */
    border: none; /* No border */
    outline: none; /* No outline */
    border-radius: $border-radius;
    border: 0;
    padding: 0 2ex;
    box-shadow: 0 12px 5px -8px rgba(black, 0.45);
    height: 3em;
    transition: all 0.3s ease;
  }
  &__facebook:hover {
    background-color: #2d4373; /* Facebook Blue */
  }
}
