$green: #2ec973;
$yellow: #ffa300;
$blue-dark: #283552;
$blue: #365d80;
$blue-light: #1dc1c1;

$color-common: #283552;
$color-common-contrast: white;

$color-primary: #f67177;
$color-primary-contrast: white;

$color-secondary: #68bcf5;
$color-secondary-contrast: white;

$color-tertiary: #9bedf8;
$color-tertiary-contrast: #283552;

$color-active: #2ec973;
$color-active-contrast: white;

$color-warning: #ffa300;
$color-warning-contrast: white;

$color-info: #1d9ac1;
$color-info-contrast: white;

$color-alert: #f95f62;
$color-alert-contrast: white;

$color-default: #e5e5e5;
$color-default-contrast: #283552;

$color-disabled: #e5e5e5;
$color-disabled-contrast: white;

$color-gray-1: #252525;
$color-gray-2: #555555;
$color-gray-3: #858585;
$color-gray-4: #b5b5b5;
$color-gray-5: #e5e5e5;
$color-gray-6: #f5f5f5;

$color-linear-gradient: linear-gradient(-90deg, $blue 40%, $color-common 100%);
$color-linear-gradient-contrast: $color-primary-contrast;

$colors: (
  primary: (
    color: $color-primary,
    contrast: $color-primary-contrast,
  ),
  primary-gradient: (
    color:
      linear-gradient(
        -8deg,
        $color-primary 50%,
        darken($color-secondary, 25) 50%
      ),
    contrast: $color-primary-contrast,
    text-class: false,
  ),
  secondary: (
    color: $color-secondary,
    contrast: $color-secondary-contrast,
  ),
  tertiary: (
    color: $color-tertiary,
    contrast: $color-tertiary-contrast,
  ),
  active: (
    color: $color-active,
    contrast: $color-active-contrast,
  ),
  warning: (
    color: $color-warning,
    contrast: $color-warning-contrast,
  ),
  info: (
    color: $color-info,
    contrast: $color-info-contrast,
  ),
  alert: (
    color: $color-alert,
    contrast: $color-alert-contrast,
  ),
  default: (
    color: $color-default,
    contrast: $color-default-contrast,
  ),
  disabled: (
    color: $color-disabled,
    contrast: $color-disabled-contrast,
    text-class: false,
  ),
  common: (
    color: $color-common,
    contrast: $color-common-contrast,
    text-class: false,
  ),
);
