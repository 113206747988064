.pz-filter {
  display: grid;
  grid-gap: $gutter-16;
  user-select: none;

  &__section {
    display: grid;
    grid-gap: $gutter-10;
  }

  &__checkbox {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    grid-gap: $gutter-10;
  }

  &__checkbox2 {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-around;
    align-items: center;
    grid-gap: $gutter-10;
  }

  &__text {
    display: grid;
  }

  &__label {
    font-size: 0.7em;
    font-family: $font-family-bold;
    cursor: pointer;
  }
}
