.center {
  margin: 0 auto;
}

.hidden {
  display: none;
}

.margin-top-20 {
  margin-top: $gutter-20;
}

.margin-bottom-20 {
  margin-bottom: $gutter-20;
}