.pz-ai {
  display: grid;
  grid-gap: $gutter-20;
  justify-content: center;
  padding-bottom: 5rem;

  &__navbar {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
  }
  &__navbar:hover {
    color: $color-secondary;
    cursor: pointer;
  }

  &__navbar:focus {
    color: $color-secondary;
  }

  &__tag {
    padding: 0 $gutter-10;
  }

  &__content {
    display: grid;
    grid-gap: $gutter-5;
    max-height: 15rem;
    overflow: auto;

    &.empty {
      img {
        width: 10rem;
      }
    }
  }

  &__form {
    display: grid;
    grid-gap: $gutter-10;

    @include from(tablet) {
      width: 50%;
      min-width: 400px;
    }
  }

  .pz-form__textarea {
    height: 4rem;
  }

  &__picture {
    @include size(2rem);
    object-fit: cover;
    border-radius: 50%;
  }

  &__comment {
    padding: $gutter-10 $gutter-20;
    background: $color-gray-6;
    box-shadow: inset 3px 0 0 $color-primary;
    display: grid;
    grid-gap: $gutter-10;
    font-size: $small-font-size;
    grid-template-columns: auto 1fr;
    align-items: start;

    &--content {
      display: grid;
    }

    &--text {
      overflow-wrap: anywhere;
    }

    &--username {
      display: grid;
      grid-gap: $gutter-10;
      grid-auto-flow: column;
      align-items: center;
      justify-content: space-between;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
