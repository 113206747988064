@keyframes color {
  0%, 100% {
    background: rgba($blue-light, 0.0);
  }

  8% {
    background: $green;
  }

  25% {
    background: rgba($green, 0.0);
  }

  33% {
    background: $yellow;
  }

  50% {
    background: rgba($yellow, 0.0);
  }

  58% {
    background: $blue;
  }

  75% {
    background: rgba($blue, 0.0);
  }

  83% {
    background: $blue-light;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-90px);
  }
}


.transition-group {
  position: absolute;
  width: 100%;

  .fade-enter {
    opacity: 0;
    z-index: 5;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
}