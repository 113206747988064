.pz-content-modal {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $gutter-40;
  width: 100%;

  @include from(tablet) {
    grid-template-columns: 2fr 1fr;
    width: 80vw;
  }
}