.pz-navbar {
  padding: $gutter-20;
  display: grid;
  grid-template-areas: "logo drop search logbtn categories actions lang";
  grid-template-columns: auto auto auto 1fr 0 0 0;
  align-items: center;
  background: white;
  grid-gap: $gutter-20;
  position: sticky;
  z-index: map_get($z-index, "navbar-menu");
  top: 0;

  @include from(laptop) {
    grid-gap: $gutter-40;
    padding: $gutter-20 $gutter-40;
    grid-template-areas: "logo categories drop search logbtn actions lang";
    grid-template-columns: auto auto 0 1fr 0 auto auto;
  }

  @include from(tablet) {
    grid-gap: $gutter-40;
    padding: $gutter-20 $gutter-40;
    grid-template-areas: "logo categories drop search logbtn actions lang";
    grid-template-columns: auto auto 0 1fr 0 auto auto;
  }

  &__dropdown {
    grid-area: drop;
    @include from(tablet) {
      display: none;
    }
  }

  &__log-btn {
    grid-area: logbtn;
    @include from(tablet) {
      display: none;
    }
  }

  &__logo {
    grid-area: logo;
    font-size: 1.375rem;
    cursor: pointer;
    width: 1.3em;
    height: 1.3em;
    padding: 0;

    img {
      border-radius: 50%;
      background: white;
    }
  }

  &__search {
    grid-area: search;
    display: flex;
    &--input {
      width: 100%;
      padding: $gutter-5 $gutter-10;
      font-size: $small-font-size;
      border-radius: $border-radius;
      border: 1px solid $color-default;
      outline: 0;
      margin: auto;
      transition: all 0.3s ease;
      text-align: center;

      &:focus {
        border-color: $color-primary;
        text-align: left;
      }

      @include from(tablet) {
        min-width: 200px;
        width: 50%;

        &:focus {
          width: 100%;
          min-width: 500px;
          text-align: center;
        }
      }
    }
  }

  &__actions {
    display: none;
    @include from(tablet) {
      grid-area: actions;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      align-items: center;
      justify-self: end;
    }
  }

  &__lang {
    display: none;
    @include from(tablet) {
      grid-area: lang;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      align-items: center;
      justify-self: end;
    }
  }

  &__categories {
    display: none;
    @include from(tablet) {
      grid-area: categories;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      align-items: center;
      justify-self: end;
    }
  }

  &__form {
    position: relative;

    &--content {
      padding: $gutter-40;
      width: 20rem;
    }
  }

  &__menu {
    position: relative;
    @include size(1.5rem, 100%);
  }

  &__menu-trigger {
    @include size(100%);
    display: grid;
    align-content: center;
    grid-gap: 0.3rem;
    transition: all 0.1s ease;
    cursor: pointer;

    i {
      width: 100%;
      height: 2px;
      border-radius: 5px;
      background: $color-common;
      position: relative;
      transition: all 0.1s ease;

      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        width: 70%;
        left: 40%;
      }

      &:nth-child(3) {
        width: 80%;
        left: 10%;
      }
    }

    &:hover,
    &:active,
    &.visible {
      grid-gap: 0.2rem;

      i {
        &:nth-child(2) {
          left: 15%;
        }
      }
    }
  }

  &__menu-container,
  &__form-container {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: map_get($z-index, navbar-menu);
    margin-top: $gutter-10;
    overflow: hidden;
    border-radius: $border-radius 0 $border-radius $border-radius;
    background: white;
    box-shadow: 0 2px 10px rgba(black, 0.25);
    font-size: $small-font-size;
  }

  &__menu-container-lang {
    position: absolute;
    top: 100%;
    right: 0;
    max-width: 100px;
    z-index: map_get($z-index, navbar-menu);
    margin-top: $gutter-10;
    overflow: hidden;
    border-radius: $border-radius 0 $border-radius $border-radius;
    background: white;
    box-shadow: 0 2px 10px rgba(black, 0.25);
    font-size: $small-font-size;
  }

  &__menu-container-lang2 {
    position: relative;
    top: 100%;
    left: 0;
    min-width: 100px;
    z-index: map_get($z-index, navbar-menu);
    margin-top: $gutter-10;
    overflow: hidden;
    border-radius: 0 $border-radius $border-radius $border-radius;
    background: white;
    box-shadow: 0 2px 10px rgba(black, 0.25);
    font-size: $small-font-size;
  }

  &__menu-container2 {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: map_get($z-index, navbar-menu);
    margin-top: $gutter-10;
    overflow: hidden;
    border-radius: 0 $border-radius $border-radius $border-radius;
    background: white;
    box-shadow: 0 2px 10px rgba(black, 0.25);
    font-size: $small-font-size;
  }

  &__menu-line {
    @include size(auto, 1px);
    background: $color-default;
    display: block;
    margin: 0 $gutter-20;
  }

  &__menu-item {
    display: grid;
    grid-template-columns: 1rem 10rem;
    grid-gap: $gutter-10;
    align-items: center;
    transition: all 0.3s ease;
    padding: $gutter-10 $gutter-20;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background: $color-common;
      color: white;
    }

    &:first-child {
      padding-top: $gutter-20;
    }

    &:last-child {
      padding-bottom: $gutter-20;
    }
  }

  &__menu-lang {
    display: flex;
    transition: all 0.3s ease;
    padding: $gutter-10 $gutter-20;
    cursor: pointer;
    overflow: auto;

    &:hover {
      background: $color-common;
      color: white;
    }
  }

  &__menu-title {
    display: grid;
    grid-template-columns: 1rem 10rem;
    grid-gap: $gutter-10;
    align-items: center;
    transition: all 0.3s ease;
    padding: $gutter-10 $gutter-20;
    white-space: nowrap;
    font-family: $font-family-bold;
    cursor: pointer;

    &:hover {
      background: $color-common;
      color: white;
    }

    &:first-child {
      padding-top: $gutter-20;
    }

    &:last-child {
      padding-bottom: $gutter-20;
    }
  }

  &__menu-btn {
    display: grid;
    grid-gap: $gutter-10;
    align-items: center;
    transition: all 0.3s ease;
    padding: $gutter-10 $gutter-20;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background: $color-common;
      color: white;
    }

    &:first-child {
      padding-top: $gutter-20;
    }

    &:last-child {
      padding-bottom: $gutter-20;
    }
  }
}

.Submenu_submenu__custom {
  width: fit-content;
  top: -5px;
  left: 100%;
}

.Item_item__custom {
  font-size: $small-font-size;
  padding: 0.25rem 0.5rem;
}

.Item_item__custom:hover {
  background-color: rgba(246, 113, 119, 0.1);
  color: $color-secondary;
}

.button_button__custom {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
}

.button_button__custom:hover {
  color: $color-secondary;
}

.button_button__custom:focus {
  color: $color-secondary;
}
