.pz-table-page {
  display: grid;
  grid-template-areas: 'filter table';
  grid-template-columns: auto 1fr;
  padding-left: $gutter-20;
  grid-gap: $gutter-20;

  &, &__filter, &__table, &__table-content {
    height: 100%;
    overflow: auto;
  }

  &__filter {
    grid-area: filter;
    min-width: 240px;
    padding: 0 $gutter-20 $gutter-20;
  }

  &__table {
    grid-area: table;
    background: white;
    border-radius: $border-radius 0 0 $border-radius;

    &-content {
      padding: $gutter-40;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-gap: $gutter-20;
    }
  }
}