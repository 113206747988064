.pz-settings {
  &__page {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0;
    @include from(tablet) {
      grid-gap: $gutter-40;
    }
  }
  &__content {
    display: grid;
    grid-auto-flow: row;
    padding: $gutter-20;
    @include from(tablet) {
      justify-content: left;
    }
  }
}
