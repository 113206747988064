
.pz-footer {
  background: $color-common;
  color: $color-common-contrast;
  padding: $gutter-40 $gutter-40 $gutter-5;

  &__container {
    display: grid;
    grid-gap: $gutter-80;
  }

  &__main {
    display: grid;
    grid-gap: $gutter-20;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gutter-80;

    @include from(tablet) {
      grid-template-columns: 1fr auto;
    }
  }

  &__columns {
    display: grid;
    justify-content: start;
    grid-gap: $gutter-40;

    @include from(tablet) {
      grid-auto-flow: column;
      grid-gap: $gutter-80;
    }
  }

  &__column {
    display: grid;
    grid-gap: $gutter-10;
    align-content: start;

    &--content {
      display: flex;
      grid-gap: $gutter-10;
      flex-wrap: wrap;

      @include from(tablet) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $gutter-5;
      }
    }

    a {
      padding: 0;
      font-size: $small-font-size;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-5;
      align-items: center;
      justify-content: start;
      white-space: nowrap;
    }

    &--title {}
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gutter-20;
    justify-content: center;

    @include from(tablet) {
      justify-content: start;
    }
  }

  &__icon {
    width: $gutter-10;
  }

  &__social a {
    font-size: $h3-font-size;

    @include from(tablet) {
      font-size: $h4-font-size;
    }
  }

  &__copyright {
    padding: $gutter-20 0;
    border-top: 1px solid;
    display: grid;
    place-items: center;
    grid-gap: $gutter-16;
    text-align: center;
    font-size: $h5-font-size;

    @include from(tablet) {
      font-size: $small-font-size;
      grid-auto-flow: column;
      justify-content: space-between;
      grid-gap: 0;
      text-align: left;
    }
  }
}