.pz-navbar-admin {
  padding: $gutter-20;
  display: grid;
  grid-auto-flow: row;
  background: white;
  grid-gap: $gutter-40;
  position: sticky;
  z-index: map_get($z-index, "navbar-menu")-1;
  max-width: fit-content;
  max-height: 600px;
  border: 1px solid $color-default;
  border-radius: 0 $gutter-10 $gutter-10 0;

  @include from(tablet) {
    padding: $gutter-40;
  }
  &--item {
    align-items: center;
    justify-content: center;
  }
  &--item:hover {
    cursor: pointer;
  }
  &--active {
    color: $color-primary;
    align-items: center;
    justify-content: center;
  }
}
