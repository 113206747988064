.pz-admin {
  &__page {
    display: grid;
    grid-auto-flow: column;
    @include from(tablet) {
    }
  }
  &__content {
    display: grid;
    grid-auto-flow: row;
    grid-gap: $gutter-40;
    padding: $gutter-20;
    @include from(tablet) {
      justify-content: left;
    }
  }

  &__dates {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gutter-20;
  }

  &__form {
    justify-self: center;
    max-width: 400px;
  }

  &__table {
    overflow: auto;
    max-width: 800px;
  }

  &__tabcontainer {
  }

  &__tabs {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    padding-bottom: 2rem;
  }
  &__tab {
    margin-right: 20px;
    font-family: $font-family-bold;
  }
  &__tab:hover {
    cursor: pointer;
  }

  &__active-tab {
    font-family: $font-family-bold;
    margin-right: 20px;
    border-bottom: 2px solid $color-primary;
  }

  &__list {
    display: grid;
    grid-template-columns: auto;
    grid-gap: $gutter-20;
    padding: $gutter-10 0;
    @include from(tablet) {
      grid-template-columns: auto auto auto;
    }

    &.empty {
      justify-content: center;
      align-items: center;
      justify-items: center;
      grid-template-columns: 1fr;
      height: $gutter-80;
      text-align: center;
    }

    &.recommend {
      justify-content: center;
      align-items: center;
      justify-items: center;
      grid-template-columns: 1fr;
      text-align: center;
    }
  }
}
