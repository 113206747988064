.pz-forum {
  &__content {
    display: grid;
    grid-auto-flow: row;
    grid-gap: $gutter-40;
    padding: $gutter-20;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  &__header {
    display: grid;
    grid-auto-flow: row;
    grid-gap: $gutter-20;
    justify-content: center;
    @include from(tablet) {
      grid-auto-flow: column;
      justify-content: left;
    }
    &--image {
      @include from(tablet) {
        @include size(6em);
      }
      @include size(100%);
    }
  }

  &__search {
    display: flex;
    &--input {
      width: 100%;
      padding: $gutter-5 $gutter-10;
      font-size: $small-font-size;
      border-radius: $border-radius;
      border: 1px solid $color-default;
      outline: 0;
      margin: auto;
      transition: all 0.3s ease;
      text-align: center;

      &:focus {
        border-color: $color-primary;
        text-align: left;
      }

      @include from(tablet) {
        min-width: 400px;
        width: 50%;

        &:focus {
          width: 100%;
          min-width: 600px;
          text-align: center;
        }
      }
    }
  }

  &__threads {
    display: grid;
    grid-gap: $gutter-10;

    &--title {
      border-bottom: 1px solid $color-gray-4;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      justify-content: space-between;
    }

    &--list {
      display: grid;

      grid-gap: $gutter-10;
      padding: $gutter-10 0;

      &.empty {
        justify-content: center;
        align-items: center;
        justify-items: center;
        grid-template-columns: 1fr;
        height: $gutter-80;
        text-align: center;
      }
    }
  }

  &__forums {
    display: grid;
    grid-gap: $gutter-10;

    &--title {
      border-bottom: 1px solid $color-gray-4;
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-20;
      justify-content: space-between;
    }

    &--list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
      grid-gap: $gutter-10;
      padding: $gutter-10 0;

      &.empty {
        justify-content: center;
        align-items: center;
        justify-items: center;
        grid-template-columns: 1fr;
        height: $gutter-80;
        text-align: center;
      }
    }
  }
}

.pz-thread {
  &__content {
    display: grid;
    grid-gap: $gutter-40;
    padding: $gutter-40 $gutter-40 $gutter-80;
    grid-template-areas: "video" "content";
    grid-template-columns: 1fr;
    align-items: flex-start;

    @include from(tablet) {
      padding: $gutter-40 $gutter-40 $gutter-80;
      grid-template-areas: "video" "content";
      grid-template-columns: 1fr;
    }
  }

  &__data {
    grid-area: video;
    display: grid;
    grid-gap: $gutter-20;
    &--linepad {
      padding-top: $gutter-40;
    }
  }

  &__practic_content {
    display: grid;
    grid-gap: $gutter-20;
    justify-items: start;

    bottom: 50px; // Ajusta la distancia desde la parte inferior
    right: 10px; // Ajusta la distancia desde la parte derecha

    @include from(tablet) {
      bottom: 30px;
      right: 30px;
    }
  }
  &__description {
    display: grid;
    grid-gap: $gutter-20;
  }

  &__file {
    display: grid;
    max-width: 800px;
  }

  &__course {
    display: grid;
    grid-gap: $gutter-16;
    justify-content: start;
    grid-auto-flow: column;
    padding: $gutter-16;
    background: $color-gray-6;
    border-left: 3px solid $color-primary;
    align-items: center;

    img {
      @include size(3em);
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: $gutter-20;
  }
}

.pz-thread-user-badge {
  display: grid;
  grid-gap: $gutter-10;
  align-items: center;
  justify-content: start;
  grid-auto-flow: column;

  img {
    @include size(2em);
    object-fit: cover;
    border-radius: 50%;
  }
}
