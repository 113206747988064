
.pz-detail-modal {
  display: grid;
  grid-gap: $gutter-20;

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 28.125rem);
    grid-gap: $gutter-40;
  }

  &__column {
    display: grid;
    grid-gap: $gutter-16;
    align-content: start;
  }

  &__section {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: $gutter-20;
    align-items: start;
  }

  &__label {
    color: $color-gray-4;
  }

  &__label-value {
    font-size: $p-font-size;
  }

  &__tags {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gutter-10;
    align-items: start;
  }

  &__tag {
    border-radius: $border-radius;
    font-size: $small-font-size;
    background: $color-default;
    padding: 0.5em 1em;
    text-align: center;
    line-height: 1em;
  }
}