
.pz-paginator {
  display: grid;
  grid-auto-flow: column;
  grid-gap: $gutter-10;
  align-items: center;
  margin-left: auto;
  user-select: none;

  svg {
    color: $color-primary;
    cursor: pointer;

    &.inactive {
      color: $color-disabled;
      pointer-events: none;
    }
  }
}