.pz-forum-card {
  border: 1px solid $color-default;
  border-radius: $border-radius;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto auto;

  &__image {
    @include size(100%, 10rem);
    object-fit: cover;
    border-radius: $border-radius;
    transition: all 0.1s ease;

    &:hover {
      transform: scale(0.95);
    }
  }

  &__content {
    display: grid;
    grid-gap: $gutter-10;
    padding: $gutter-10;
    align-content: start;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &__reactions {
    display: grid;
    grid-gap: $gutter-10;
    grid-auto-flow: column;
    padding: $gutter-10 $gutter-10 0;
    justify-content: start;
  }

  &__reaction {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gutter-5;
    align-items: center;
  }

  &__username {
    display: grid;
    grid-gap: $gutter-5;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
    margin-top: $gutter-10;
    background: $color-gray-6;
    padding: $gutter-5 $gutter-10;
    font-size: $h5-font-size;

    img {
      @include size(1.5em);
      object-fit: cover;
      border-radius: 50%;
      align-self: start;
    }
  }
}
