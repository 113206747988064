.pz-navbar-page {
  background: $color-gray-6;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: $gutter-20;
  height: 100%;
  overflow: auto;

  &.no-background {
    background: unset;
  }
}