.pz-profile-card {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: $gutter-20;
  grid-template-areas: "photo" "header" "body" "links" "detail" "detail2";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  justify-items: center;
  text-align: center;

  @include from(tablet) {
    grid-gap: $gutter-5 $gutter-20;
    grid-template-areas: "photo header detail detail2" "photo body detail detail2" "photo links detail detail2";
    grid-template-columns: auto 28rem auto auto;
    grid-template-rows: 1fr auto auto;
    justify-content: initial;
    text-align: left;
    justify-items: start;
  }

  &__photo {
    @include size(10rem);
    grid-area: photo;
    background: $color-primary;
    display: grid;
    justify-content: center;
    align-self: start;
    align-content: center;
    border-radius: 50%;

    @include from(tablet) {
      @include size(15rem);
    }
  }

  &__image {
    @include size(8rem);
    border-radius: 50%;
    background: rgba(white, 0.5);
    margin: $gutter-16;
    border: 3px solid white;
    box-shadow: 0 20px 10px -10px rgba(black, 0.5);

    @include from(tablet) {
      @include size(10rem);
    }
  }

  &__header {
    padding: 0 $gutter-40;

    .edit {
      font-size: $h4-font-size;
      cursor: pointer;
    }

    &--title {
      font-family: $font-family-bold;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1rem;
      align-items: center;
    }
  }

  &__body {
    padding: $gutter-20 $gutter-40;
    display: grid;
    align-content: start;
    background: $color-gray-6;
    grid-gap: $gutter-10;
    justify-self: stretch;
    border-radius: $border-radius;
    text-align: left;
    word-break: break-word;
  }

  &__links {
    width: 100%;
    grid-area: links;
    display: grid;
    grid-gap: $gutter-5 $gutter-20;
    padding: $gutter-20 $gutter-40;
    grid-template-columns: repeat(auto-fit, auto-fit, auto-fit);
    justify-items: center;

    @include from(tablet) {
      justify-items: start;
    }
  }

  &__link {
    display: grid;
    justify-content: start;
    grid-auto-flow: column;
    grid-gap: $gutter-5;
    align-items: center;
  }

  &__detail {
    grid-area: detail;
    display: grid;
    grid-gap: $gutter-20;
  }

  &__detail2 {
    grid-area: detail2;
    display: grid;
    grid-gap: $gutter-20;
  }

  &__experiences {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $gutter-20;
  }

  &__experience {
    display: grid;
    justify-items: center;
    grid-gap: $gutter-10;

    &--image {
      @include size(6em);
      background: $color-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    &--imageComp {
      @include size(3em);
      background: $color-primary-contrast;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    &--number {
      font-weight: bolder;
      @include size(2em);
      text-align: center;
      background: $color-primary;
      color: $color-primary-contrast;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    &--badge {
      @include size(2em);
      position: absolute;
      font-weight: bolder;
      margin-left: 4rem;
      @include from(tablet) {
        @include size(3em);
        margin-left: 5rem;
      }
    }
  }
}
