.quiz {
  display: grid;

  &__form {
    display: grid;

    &__question {
      margin: $gutter-20;
      padding: $gutter-20;
      border: 1px solid $color-primary;
      border-radius: $gutter-10;
    }
  }
}
