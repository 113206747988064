.pz-notification {
  position: fixed;
  z-index: map_get($z-index, notification);
  bottom: $gutter-20;
  width: 100%;
  display: flex;

  &__box {
    max-width: 70vw;
    margin: auto;
    padding: .5em $gutter-20;
    border-radius: $border-radius;
    display: grid;
    grid-gap: $gutter-10;
    grid-template-columns: 1fr auto;
    align-items: center;
    border: 1px solid;
    user-select: none;
    font-size: $small-font-size;
  }

  &__content {
    border-right: 1px dashed;
    padding-right: $gutter-10;
  }

  &__close-button {
    width: 12px !important;
    height: 12px !important;
    cursor: pointer;
  }
}