.pz-breadbrumbs{
    display: flex;
    flex-direction: row;
    padding: $gutter-20 $gutter-40;

    &__crumb{
        margin-right: $margin;
        &::after{
            content: '>';
            margin-left: $margin;
        }
        &:hover{
            color: $color-primary;
        }
        &:last-child::after{
            display: none;
        }
    }
}



