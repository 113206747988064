.pz-landing {
  display: grid;
  grid-gap: $gutter-16;

  .pz-landing__section {
    padding: $gutter-40 $gutter-40 $gutter-40;

    @media (min-width: 768px) {
      padding: $gutter-40 $gutter-80 $gutter-40;
    }
  }

  .pz-landing__cover--content {
    display: grid;
    grid-gap: $gutter-16;
    align-items: center;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 2fr;
      align-items: flex-start;
    }
  }

  .pz-landing__content {
    margin-right: 20px;

    @media (min-width: 768px) {
      transform: translateX(
        20px
      ); /* Ajusta el desplazamiento horizontal del contenido para pantallas grandes */
    }

    @media (min-width: 992px) {
      transform: translateX(
        50px
      ); /* Ajusta el desplazamiento horizontal del contenido para pantallas extra grandes */
    }
  }

  .pz-landing__video-container {
    display: none;
    @include from(tablet) {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (min-width: 768px) {
        justify-content: flex-end;
        margin-top: 0;
        margin-left: auto;
        transform: translateX(
          20px
        ); /* Ajusta el desplazamiento horizontal del video para pantallas grandes */
      }

      @media (min-width: 992px) {
        transform: translateX(
          135px
        ); /* Ajusta el desplazamiento horizontal del video para pantallas grandes */
      }
    }
  }

  .pz-landing__cover--video {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-top: 80px;
    border-radius: 8px; /* Agrega bordes redondeados al video */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    @media (min-width: 768px) {
      max-width: 100%;
      max-height: 100%;
      margin-top: 0;
    }
  }

  &__section {
    padding: $gutter-40 $gutter-40 $gutter-40;
    display: grid;
    grid-gap: $gutter-40;

    @include from(tablet) {
      padding: $gutter-40 $gutter-80 $gutter-40;
    }
  }

  &__form {
    max-width: 400px;
    justify-self: center;
  }

  &__cover {
    justify-items: center;
    align-content: center;
    display: grid;
    grid-gap: $gutter-40;
    padding: $gutter-40 $gutter-40 $gutter-40;

    @include from(tablet) {
      padding: $gutter-40 $gutter-80 $gutter-40;
    }

    &--content {
      display: grid;
      grid-gap: $gutter-10;
      justify-items: center;
      text-align: center;
      max-width: 900px;
      text-shadow: 0 2px 3px rgba(black, 0.25);
    }

    &--image {
      width: 100%;

      @include from(tablet) {
        width: 50%;
      }
    }
  }

  &__button {
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    &--icon {
      margin-left: $gutter-10;
      transition: all 0.3s ease;
    }

    &:hover {
      .pz-landing__button--icon {
        margin-left: $gutter-20;
      }
    }
  }

  &__services-section {
    background: $color-gray-6;
  }

  &__services {
    display: grid;
    grid-gap: $gutter-80;
    grid-template-columns: repeat(1, 80%);
    justify-content: center;

    &--service {
      display: grid;
      grid-gap: $gutter-40;
      grid-template-columns: 1fr;
      grid-template-areas: "content";
      text-align: center;

      @include from(tablet) {
        align-items: center;
        justify-items: start;
        text-align: left;
        grid-template-areas: "image content";
        grid-template-columns: 1fr 2fr;
      }

      &:nth-child(odd) {
        grid-template-columns: 1fr;
        grid-template-areas: "content";

        @include from(tablet) {
          text-align: right;
          justify-items: end;
          grid-template-areas: "content image";
          grid-template-columns: 2fr 1fr;
        }
      }

      &--image {
        grid-area: image;
        display: none;

        @include from(tablet) {
          display: inherit;
        }
      }

      &--content {
        grid-area: content;
        max-width: 800px;
      }
    }
  }

  &__how-works {
    display: grid;
    grid-gap: $gutter-20;
    justify-content: center;

    @include from(tablet) {
      grid-auto-flow: column;
      grid-gap: $gutter-80;
    }

    &--column {
      display: grid;
      grid-gap: $gutter-20;
      max-width: 400px;
      text-align: center;
      justify-items: center;

      @include from(tablet) {
        text-align: left;
        justify-items: initial;
      }

      &.right {
        @include from(tablet) {
          text-align: right;
        }
      }
    }

    &--item {
      display: grid;
      grid-gap: $gutter-20;
      grid-template-areas: "image" "content";
      justify-content: center;
      text-align: center;
      justify-items: center;
      max-width: 60%;

      @include from(tablet) {
        justify-items: start;
        grid-auto-flow: column;
        grid-template-areas: "image content";
        text-align: left;
        max-width: 100%;
      }

      &.invert {
        @include from(tablet) {
          grid-template-areas: "content image";
          justify-items: end;
          text-align: right;
        }
      }

      &.column {
        grid-template-areas: "image" "content";
        grid-auto-flow: row;
        justify-items: center;
        text-align: center;
      }

      &--image {
        grid-area: image;
        width: $gutter-40;
      }

      &--secondimage {
        grid-area: image;
        width: 7.5rem;
      }

      &--thirdimage {
        grid-area: image;
        width: 11.5rem;
      }

      &--fourthimage {
        grid-area: image;
        height: 10rem;
      }

      &--content {
        grid-area: content;
        max-width: 400px;
      }
      &--content2 {
        grid-area: content;
        max-width: 400px;
        height: 8rem;
      }
    }
  }

  @media (max-width: 767px) {
    &__video-container {
      justify-content: center; /* Alinear el video horizontalmente en dispositivos móviles */
      align-items: center; /* Alinear el video verticalmente en dispositivos móviles */
    }
  }
  &__audio--visual {
    display: grid;
    grid-gap: $gutter-16;
    align-items: center;

    @media (min-width: 768px) {
      grid-template-columns: 2fr 3fr;
      align-items: flex-start;
    }
    &__image--cover {
      grid-area: image;
      display: inherit;

      @include from(tablet) {
        display: inherit;
      }
    }
    &__image {
      min-height: 150px;
      max-height: 200px;
      grid-area: image;
      display: inherit;

      @include from(tablet) {
        display: inherit;
      }
    }

    &__small {
      padding: 0 8rem;
    }

    &__table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      min-width: 300px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

      thead tr {
        background-color: $color-primary;
        color: #ffffff;
        text-align: left;
      }
      td {
        padding: 8px;
      }
      th,
      tr {
        padding: 12px 15px;
      }
      tbody tr {
        border-bottom: 1px solid #dddddd;
      }
      tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
      }
      tbody tr:last-of-type {
        border-bottom: 2px solid $color-primary;
      }
      tbody tr.active-row {
        font-weight: bold;
        color: $color-primary;
      }
    }
  }
  &__carousel {
    display: grid;
    justify-content: center;
    @include from(mobile-s) {
      padding: $gutter-20 0;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 17.9rem));
    }
    @include from(tablet) {
      padding: $gutter-20 0;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 50rem));
    }

    @include from(laptop) {
      padding: $gutter-20 0;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 50rem));
    }
  }

  &__portfolio {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;
    @include from(tablet) {
      grid-auto-flow: column;
      padding: 0 5rem;
    }

    &--profile {
      justify-self: center;
      @include size(10rem);
      @include from(tablet) {
        @include size(15rem);
      }
    }

    &--image {
      // @include from(tablet) {
      //   @include size(40rem);
      // }
    }
  }

  &__package {
    &__card {
      padding: 2rem;
      width: 20rem;
      border: 1px solid $color-default;
      border-radius: $border-radius;
    }
  }
}

.wrapper {
  max-width: 600px;
  margin: 50px auto 10px;
  padding: 60px 30px;
  border: 2px solid $color-secondary;
  border-radius: 2rem;
}

.wrapper__title {
  text-transform: capitalize;
  margin: 0 0 40px;
}

.calculator__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
}

.calculator__row:last-child {
  margin-bottom: 0;
}

.calculator__row label {
  text-transform: capitalize;
  font-weight: 700;
}

.calculator__bill {
  width: 50%;
}

.calculator__tip {
  width: 100%;
}
.calculator__tip::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}
.calculator__tip::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.calculator__info {
  text-transform: capitalize;
  margin: 0;
}
