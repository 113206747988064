.pz-username-badge {
  display: grid;
  grid-gap: $gutter-10;
  grid-auto-flow: column;
  grid-template-areas: "badge username";
  align-items: center;
  user-select: none;

  &.invert {
    grid-template-areas: "username badge";
  }

  &__initials {
    @include size(2em);
    background: $color-common;
    color: $color-common-contrast;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: badge;
    font-size: 0.8em;
  }

  &__pic {
    @include size(2em);
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: badge;

    img {
      @include size(2em);
      border-radius: 50%;
    }
  }

  &__username {
    grid-area: username;
    font-size: 0.8em;
  }
}
