.reactions-warn {
  backdrop-filter: blur(1.5px); /* Mayor efecto de borroso */
  position: absolute;
  width: 90%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(128, 128, 128, 0.5); /* Mayor opacidad */
  padding: 5px; /* Menor espacio interno */
  border-radius: 8px;
  text-align: center;
  font-weight: 900;
  color: white;
  z-index: 2;
}
.pz-lesson {
  &__content {
    display: grid;
    grid-gap: $gutter-40;
    padding: $gutter-40 $gutter-40 $gutter-80;
    grid-template-areas: "video" "description" "content";
    grid-template-columns: 1fr;
    align-items: start;

    @include from(tablet) {
      padding: $gutter-40 0 $gutter-80;
      grid-template-areas: "video description" "content description";
      grid-template-columns: 2fr 1fr;
    }
  }

  &__data {
    grid-area: video;
    display: grid;
    grid-gap: $gutter-20;
    &--linepad {
      padding-top: $gutter-40;
    }
  }

  &__practic_content {
    display: grid;
    grid-gap: $gutter-20;
    justify-items: start;

    bottom: 50px; // Ajusta la distancia desde la parte inferior
    right: 10px; // Ajusta la distancia desde la parte derecha

    @include from(tablet) {
      bottom: 30px;
      right: 30px;
    }
  }
  &__description {
    grid-area: description;
    display: grid;
    grid-gap: $gutter-20;
  }

  &__file {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__course {
    display: grid;
    grid-gap: $gutter-16;
    justify-content: start;
    grid-auto-flow: column;
    padding: $gutter-16;
    background: $color-gray-6;
    border-left: 3px solid $color-primary;
    align-items: center;

    img {
      @include size(3em);
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: $gutter-20;
  }

  &__practical-item {
    border: 1px solid $color-default;
    border-radius: $border-radius;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    cursor: pointer;

    &--type {
      background: $color-primary;
      color: $color-primary-contrast;
      padding: $gutter-10;
      display: grid;
      grid-auto-columns: auto;
      grid-auto-flow: column;
      grid-gap: $margin;
      justify-content: space-between;
    }

    &--content {
      padding: $gutter-10;
      display: grid;
      align-content: start;
    }

    &--description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &--reactions {
      display: grid;
      grid-gap: $gutter-10;
      grid-auto-flow: column;
      padding: $gutter-10 $gutter-10 0;
      justify-content: start;
    }

    &--reaction {
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-5;
      align-items: center;
    }

    &--user {
      display: grid;
      grid-gap: $gutter-5;
      align-items: center;
      justify-content: start;
      grid-auto-flow: column;
      margin-top: $gutter-10;
      background: $color-gray-6;
      padding: $gutter-5 $gutter-10;
      font-size: $h5-font-size;

      img {
        @include size(1.5em);
        object-fit: cover;
        border-radius: 50%;
        align-self: start;
      }
    }
  }
}
