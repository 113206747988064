
.pz-table-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow: auto;

  &__table {
    height: 100%;
    overflow: auto;
  }
}

.pz-table {
  position: relative;
  font-size: $table-font-size;
  border-spacing: 0;
  width: 100%;

  &__caption {
    text-align: left;
    font-family: $font-family-bold;
    border-bottom: 1px solid $color-primary;
    font-size: $button-font-size;
    padding: $gutter-10 0;
    margin-bottom: $gutter-20;

    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;
    grid-gap: $gutter-10;
    align-items: center;

    &-actions {
      display: grid;
      grid-auto-flow: column;
      grid-gap: $gutter-10;
    }
  }

  thead {
    text-align: left;
    font-family: $font-family-bold;
    line-height: 2.5em;
  }

  tr {
    &:hover {
      background: $color-gray-6;
    }
    &.selected {
      background: $color-gray-5;
    }
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    padding: $gutter-10 $gutter-10 0;
  }

  td, th {
    white-space: nowrap;
  }

  td {
    line-height: 1.5em;
    padding: $gutter-5 $gutter-10;
    horiz-align: center;
  }
}