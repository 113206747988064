
.pz-app-logo {
  display: grid;
  grid-auto-flow: column;
  grid-template-areas: 'mo name';
  align-items: center;
  color: $color-common;
  background: transparent;
  user-select: none;
  font-family: $font-family-title;
  justify-content: start;
  font-size: $h1-font-size;
  grid-gap: .2em;

  &.white {
    color: white;
  }

  &__mo {
    height: 1.2em;
  }

  &__name {
    grid-area: name;
    font-size: 1em;
    letter-spacing: -0.05em;
  }
}