.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.line {
  height: 1px;
  background: $color-default;
  display: block;
}

.bold {
  font-family: $font-family-bold;
}

.text-primary {
  color: $color-primary;
}

.text-active {
  color: $color-active;
}

.small-list {
  font-size: 12px;
}
