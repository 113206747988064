.pz-profile-user-card {
  border: 1px solid $color-default;
  border-radius: $border-radius;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto auto;
  position: relative;

  &__image {
    @include size(100%, 12rem);
    object-fit: fill;
    border-radius: $border-radius;
    transition: all 0.1s ease;

    &:hover {
      transform: scale(0.95);
    }
  }

  &__content {
    display: grid;
    grid-gap: $gutter-10;
    padding: $gutter-10;
    align-content: start;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 2rem;
  }

  &__name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &__points {
    display: grid;
    grid-auto-flow: row;
    grid-gap: $gutter-10;
  }

  &--badge {
    @include size(3em);
    position: absolute;
    top: -5px;
    right: -5px;
    font-weight: bolder;
    &:hover {
      transform: scale(1.1);
    }
  }
}
