.pz-testimony-card {
  border: 1px solid $color-default;
  border-radius: $border-radius;
  overflow: hidden;
  display: grid;
  grid-template-rows: 10rem auto;

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: $gutter-20;
    padding: $gutter-10 0;
    align-content: center;
  }

  &__content {
    display: grid;
    grid-gap: $gutter-10;
    padding: $gutter-10;
    align-content: start;
  }

  &__description {
  }

  &__username {
    display: grid;
    grid-gap: $gutter-5;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
    margin-top: $gutter-10;
    background: $color-gray-6;
    padding: $gutter-5 $gutter-10;
    font-size: $h5-font-size;

    img {
      @include size(1.5em);
      object-fit: cover;
      border-radius: 50%;
      align-self: start;
    }
  }
}
