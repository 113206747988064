.pz-form {
  display: grid;
  grid-gap: $gutter-20;

  /*
  * Common
  */

  &__icon {
    @include size(2rem);
    border-radius: 50%;
    grid-area: icon;
    display: grid;
    justify-content: center;
    align-content: center;
  }

  /*
  * Fields
  */

  @at-root &__field {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "label" "input";
    margin-bottom: 6px;

    &.icon {
      grid-template-areas: "label label" "input icon";
      grid-template-columns: 1fr auto;
    }
  }

  @at-root &__check-field {
    display: grid;
    justify-content: start;
    align-items: center;
    grid-template-areas: "label input";
    grid-template-columns: auto auto;
    grid-gap: $gutter-10;

    label,
    [class*="label"] {
      cursor: pointer;
    }
  }

  /*
  * Labels
  */

  @at-root &__label {
    grid-area: label;
    padding: 0;
    line-height: 1rem;
    user-select: none;
    font-size: $small-font-size;
  }

  @at-root &__text-label {
    grid-area: label;
    padding: 0;
    user-select: none;
    font-size: $small-font-size;
  }

  /*
  * Inputs
  */

  @at-root &__input {
    grid-area: input;
    -webkit-appearance: none;
    appearance: none;
    background: white;
    outline: 0;
    font-size: 0.875rem;
    border: 0;
    border-bottom: 1px solid $color-gray-5;
    padding: $gutter-5 0;
    width: 100%;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      transition: all 5000s ease-in-out 0s;
    }
  }
  @at-root &__error_message {
    font-size: 10px;
    color: #f61717;
    height: 0;
  }

  @at-root &__only-input {
    -webkit-appearance: none;
    appearance: none;
    background: white;
    outline: 0;
    font-size: 0.7rem;
    border-radius: $border-radius;
    padding: 0.5em 1em;
    border: 0;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      transition: all 5000s ease-in-out 0s;
    }
  }

  @at-root &__file-input {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    grid-template-areas: "name size";
    grid-gap: $gutter-10;
    align-items: center;
    justify-content: start;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }

    input {
      display: none;
    }

    &--size,
    &--name {
      white-space: nowrap;
      font-size: 0.8em;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--size {
      grid-area: size;
      border-radius: $border-radius;
      border: 1px solid;
      padding: 0 $gutter-5;
    }

    &--name {
      grid-area: name;
      font-size: 0.8em;
    }
  }

  @at-root &__textarea {
    grid-area: input;
    box-shadow: 0 0 2px rgba($color-gray-1, 0.5);
    -webkit-appearance: none;
    font-size: 0.875rem;
    border-radius: 0.625rem;
    line-height: 1.75em;
    appearance: none;
    background: white;
    outline: 0;
    padding: $gutter-5 $padding;
    border: 0;
    height: 8rem;
    resize: none;
  }

  @at-root &__toggle,
    &__checkbox {
    position: relative;

    input,
    span {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
    }

    input {
      z-index: 3;
      opacity: 0;
      cursor: pointer;
    }

    span {
      color: $color-default;
      border: 1px solid;
      transition: all 0.3s ease;
      background: white;

      &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        transition: all 0.3s ease;
      }
    }

    input:checked {
      & ~ span {
        color: $color-primary;

        &::before {
          background: $color-primary;
        }
      }
    }

    input:focus {
      & ~ span::before {
        background: rgba($color-primary, 0.25);
      }

      &:checked {
        & ~ span::before {
          background: rgba($color-primary, 0.75);
        }
      }
    }
  }

  @at-root &__toggle {
    @include size(1.875rem, 1.0625rem);

    span {
      border-radius: $border-radius;

      &::before {
        @include size(0.8125rem);
        left: 1px;
        top: 1px;
        background: $color-default;
      }
    }

    input:checked {
      & ~ span::before {
        left: 50%;
        background: $color-primary;
      }
    }
  }

  @at-root &__checkbox {
    @include size(1.0625rem);

    span {
      border-radius: 3px;

      &::before {
        @include size(0.6875rem);
        left: 2px;
        top: 2px;
        background: white;
      }
    }
  }

  /*
  * Validators
  */

  @at-root &__input,
    &__textarea {
    &:valid {
      & ~ .mo-form__label,
      & ~ .mo-form__text-label {
        font-family: $font-family-bold;
        color: $color-active;
      }
    }

    &:focus {
      & ~ .mo-form__label,
      & ~ .mo-form__text-label {
        font-family: $font-family-bold;
        color: $color-info;
      }
    }
  }

  @at-root &__inputdisable {
    -webkit-appearance: none;
    appearance: none;
    background: white;
    outline: 0;
    font-size: 0.875rem;
    border: 0;
    border-bottom: 1px solid $color-gray-5;
    padding: $gutter-5 0;
    width: 100%;
  }

  .react-tagsinput-tag {
    background-color: $color-primary;
    border-radius: $gutter-10;
    border: 1px solid $color-primary;
    color: $color-primary-contrast;
    display: inline-block;
    font-family: $font-family-title;
    font-size: $p-font-size;
    margin-top: $gutter-10;
    margin-right: $gutter-10;
    padding: $gutter-5;
  }

  &__tagsinput {
    border-bottom: 1px solid $color-gray-5;
  }
}
